'use client'

export default function LandingBlockFairPrice(props: any) {
    return (
        <div style={{maxWidth: 600}} className={"px-4 lg:px-0"}>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                <span className={"text-4xl font-semibold"}>Determine {"stock's"} real price</span>
                <div className={"border-b border-b-fuchsia-200 w-10 md:w-20 lg:w-96 my-7"}></div>
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                How do you know if you should <span className={"font-semibold"}>buy or sell</span>?
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                {"The Bullish Trade pulls all the complex data about the company. It's balance sheet, reports, valuations data, growth projections and more."}
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                And then we show it in a visual, simple, <span className={"font-semibold"}>easy to understand</span> way.
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                Who knows if the P/E of the company is high? How to measure if last earnings report was good or not?
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                We do. We show the company data <span className={"font-semibold"}>compared to</span> the industry and the sector. And we show the historical trend as well.
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                {"You can see how company performs compared to it's competitors."} How fairly it is priced compared to others. And make a decision.
            </div>
        </div>
    )
}
