'use client'

export default function LandingBlocksTldr(props: any) {
    return (
        <div style={{maxWidth: 600}} className={"px-4 lg:px-0"}>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                <span className={"text-4xl font-semibold"}>{`TL;DR`}</span>
                <div className={"border-b border-b-fuchsia-400 w-10 md:w-20 lg:w-96 my-7"}></div>
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                <span className={"font-bold"}>Market Insights</span> - see most interesting moves of the day, and know what drives them.
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                <span className={"font-bold"}>Company Fundamentals</span> - most important company data presented in a simple and visual way, compared to competition and market. Explained.
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                <span className={"font-bold"}>Options Finder</span> - automate your options search and find trades for your taste. Learn more about each trade, and understand your win chances.
            </div>
        </div>
    )
}
