'use client'

export default function LandingBlockFundamentals(props: any) {
    const currentYear = new Date().getFullYear();
    return (
        <div style={{maxWidth: 600}} className={"px-4 lg:px-0"}>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                <span className={"text-4xl font-semibold"}>Actionable fundamentals</span>
                <div className={"border-b border-b-fuchsia-400 w-10 md:w-20 lg:w-96 my-7"}></div>
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                Make informed decisions based on our detailed yet easy to read <span className={"font-bold"}>visualized company fundamentals</span>.
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                {"Yes, just like everyone else, we show comprehensive insights into a company's performance, financial health, and market position."}
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                But we actually <span className={"font-bold"}>describe</span> what each of them means and how it affects the stock price.
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                And you can see and compare how similar companies are doing.
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                And since it is {currentYear}, you can even <span className={"font-bold"}>consult with The Bullish Trade AI</span>, which will help you make the right decision.
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                And did we mention we display <span className={"font-bold"}>senate trading</span> data for each stock?
            </div>
        </div>
    )
}
