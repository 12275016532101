'use client'

import AppConstants from "~/components/constants/app_constants";
import {useEffect, useState} from "react";
import posthog from "posthog-js";

export default function LandingBlockTop(props: any) {
    const [tryFreeDownloadsPage, setTryFreeDownloadsPage] = useState(false)
    useEffect(() => {
        setTryFreeDownloadsPage(posthog.getFeatureFlag('try_free_downloads_page') === 'test');
    });

    return (
        <div style={{maxWidth: 600}} className={"px-4 lg:px-0"}>
            <div className={"mb-7 md:mb-4 lg:mb-3 font-bold text-4xl"} style={{lineHeight: 1.4}}>
                {/*<span className={"font-semibold"}>Stop losing money</span> on wrong trades.*/}
                Stop losing money on wrong trades.

                <div className={"border-b border-b-fuchsia-400 w-10 md:w-20 lg:w-96 my-7"}></div>
            </div>
            <div className={"mb-7 md:mb-4 lg:mb-3 font-light leading-8"}>
                {"Don't know if the stock is "}
                <span className={"font-semibold"}>overpriced </span>
                or undervalued?

            </div>
            {/*<div className={"mb-7 md:mb-4 lg:mb-3 font-light"}>*/}
            {/*    Set your risk and <span*/}
            {/*    className={"font-semibold text-emerald-400"}>profit</span> expectations.*/}
            {/*</div>*/}
            <div className={"mb-7 md:mb-4 lg:mb-3 font-light"}>
                {/*Select <span className={"font-semibold text-amber-400"}>strategies</span> and*/}
                Not sure if you should <span className={"font-semibold"}>buy more</span>, or sell ASAP?
            </div>
            <div className={"mb-7 md:mb-4 lg:mb-3 font-light"}>
                {/*Select <span className={"font-semibold text-amber-400"}>strategies</span> and*/}
                Thinking if you should go with a <span className={"font-semibold"}>bullish</span> or bearish trading
                strategy?
            </div>


            {/*<div className={"mb-7 md:mb-4 lg:mb-3 font-light"}>*/}
            {/*    Bullish Trade simplifies stock and options trading!*/}
            {/*</div>*/}
            <div className={"mb-7 md:mb-4 lg:mb-3 font-light"}>
                Discover fair prices, visualize fundamentals, and find the <span
                className={"font-semibold"}>best trades</span> in real-time with The Bullish Trade.
                {/*We scan, <span className={"font-semibold"}>you profit!</span> 💰*/}
            </div>

            <div className={"mt-16 lg:mr-16 lg:flex lg:flex-row justify-center"}>
                <div>
                    <div className={"text-center"}>
                            <span
                                // onClick={() => props.navigateToApp("https://app.thebullish.trade", "app", "top-try-now")}
                                onClick={() => props.navigateToApp(tryFreeDownloadsPage ? "https://thebullish.trade/downloads" : "https://app.thebullish.trade",
                                    tryFreeDownloadsPage ? "downloads" : "app", "top-try-now")}
                                className={"cursor-pointer btn-primary-grad text-white px-8 py-4 text-xl font-bold rounded-xl"}>TRY FOR
                                FREE</span>
                        <div className={"mt-5 text-center"}>
                            <div className={"text-xs text-gray-500"}>No credit card required. Instant
                                signup.
                            </div>
                            <div className={"text-xs text-gray-500"}><span
                                className={"font-bold"}>Free plans&nbsp;</span>available.
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"w-10 text-gray-400 text-center hidden lg:block mr-2"}>or</div>
                <div className={"text-center mt-6 lg:mt-0"}>
                        <span
                            onClick={() => props.navigateToApp(tryFreeDownloadsPage ? "https://thebullish.trade/downloads" : "https://app.thebullish.trade",
                                tryFreeDownloadsPage ? "downloads" : "app", "header-block-login")}
                            className={"cursor-pointer border border-violet-100 text-violet-100 px-8 py-4 text-xl font-bold rounded-xl"}>LOGIN</span>
                </div>
            </div>
        </div>
    )
}
