'use client'

export default function LandingBlockOptions(props: any) {
    return (
        <div style={{maxWidth: 600}} className={"px-4 lg:px-0"}>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                <span className={"text-4xl font-semibold"}>Stop losing with options</span>
                <div className={"border-b border-b-fuchsia-400 w-10 md:w-20 lg:w-96 my-7"}></div>
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                One of the top features of The Bullish Trade is <span className={"font-bold"}>options finder</span>.
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                Even more, initially The Bullish Trade was made for options traders, by options traders.
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                Select the companies you target. Select your favorite strategies (Credit/Debit spreads, Iron Condors, Strangles, whatever).
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                And The Bullish Trade will <span className={"font-bold"}>scan the whole market</span>, all expirations, all strikes to find only the best options trades.
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                The app will also <span className={"font-bold"}>describe each trade in detail</span>, so you know your max profit and loss, probability of the win, and more.
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                And yes, we have <span className={"font-bold"}>free plans</span>!
            </div>
        </div>
    )
}
