'use client'

export default function LandingBlockKeyMoves(props: any) {
    return (
        <div style={{maxWidth: 600}} className={"px-4 lg:px-0"}>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                <span className={"text-4xl font-semibold"}>Market Insights</span>
                <div className={"border-b border-b-fuchsia-300 w-10 md:w-20 lg:w-96 my-7"}></div>
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                Stay updated on <span className={"font-bold"}>key market moves</span>. Get insights on the latest trends and news.
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                See most interesting changes in the market today. Which industries are growing, and which companies are pulling them up (or down!).
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                Find interesting <span className={"font-bold"}>trade opportunities</span>: see biggest price changes, volume spikes, most overvalued or underpriced stocks.
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                <span className={"font-bold"}>Automatically scan options</span> market to find trades according to your budget, risk tolerance and profit expectations.
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                Find similar price moves in the history with our <span className={"font-bold"}>Correlations feature</span> to know if the trend is likely to continue.
            </div>
        </div>
    )
}
