'use client'

export default function LandingBlockWhatIsBullishtrade(props: any) {
    return (
        <div style={{maxWidth: 600}} className={"px-4 lg:px-0"}>
            <div className={"text-xl mb-7 font-light"}>
                <span className={"text-4xl font-semibold"}>What is The Bullish Trade? 🧐</span>
                <div>
                    <div className={"border-b border-b-fuchsia-400 w-10 md:w-20 lg:w-96 my-7"}></div>
                </div>
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                The Bullish Trade is the secret app for stock and option traders.
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                First, we tell you if the stock is <span className={"font-bold"}>overpriced or undervalued</span>.
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                Second, you <span className={"font-bold"}>compare</span> {"company's fundamentals to its competitors and industry/sector averages."}
            </div>
            <div className={"text-xl mb-7 md:mb-4 lg:mb-3 font-light"}>
                Third, we scan the whole market and find the <span className={"font-bold"}>best option trades</span> according to your expectations.
                 <div className={"text-sm text-gray-500 mt-2"}>
                    All expirations. All strikes. All deltas. All spreads. All greeks.
                </div>
            </div>
        </div>
    )
}
