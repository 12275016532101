'use client'
// import {clarity} from 'react-microsoft-clarity';
import {useEffect, useRef, useState} from "react";
import {motion} from "framer-motion";
// import {push} from "@socialgouv/matomo-next";
import mixpanel from 'mixpanel-browser';
import 'video-react/dist/video-react.css';
import {Player} from 'video-react';
import posthog from 'posthog-js'
import {handleScroll} from "~/components/handle-scroll/handle-scroll";
import FloatingTryForFree from "~/components/floating-try-for-free/floating-try-for-free";
import AppHead from "~/components/head/head";
import TopNavBar from "~/components/top-nav-bar/top-nav-bar";
import LandingBlockTop from "~/components/landing-blocks/landing-block-top/landing-block-top";
import {navigateToApp} from "~/components/navigate-to-top/navigate-to-top";
import LandingBlockPricing from "~/components/landing-blocks/landing-block-pricing/landing-block-pricing";
import EmailForm from "~/components/email-form/email-form";
import Footer from "~/components/footer/footer";
import LandingBlockWhatIsBullishtrade
  from "~/components/landing-blocks/landing-block-what-is-bullishtrade/landing-block-what-is-bullishtrade";
import LandingBlockFairPrice from "~/components/landing-blocks/landing-block-fair-price/landing-block-fair-price";
import LandingBlockKeyMoves from "~/components/landing-blocks/landing-block-key-moves/landing-block-key-moves";
import LandingBlockFundamentals
  from "~/components/landing-blocks/landing-block-fundamentals/landing-block-fundamentals";
import LandingBlockOptions from "~/components/landing-blocks/landing-block-options/landing-block-options";
import LandingBlocksTldr from "~/components/landing-blocks/landing-blocks-tldr/landing-blocks-tldr";

// import dynamic from 'next/dynamic'
// const ReactPlayer = dynamic(() => import("react-player/file"), {ssr: false});
// const ReactPlayer = dynamic(() => import("react-player/youtube"), {ssr: false});

export default function Landing3(params: any) {
  const downloadLinks = params.downloadLinks;
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visibleHeader, setVisibleHeader] = useState(false)
  const [isPlayerReady, setPlayerReady] = useState(false)
  const [shouldPlay, setShouldPlay] = useState(false)
  const player = useRef<any>();
  const [tryFreeDownloadsPage, setTryFreeDownloadsPage] = useState(false)

  console.log('A/B', tryFreeDownloadsPage)
  useEffect(() => {
    // clarity.init('j9gx132pyl');
    // push(["trackEvent", "Landing Page Open", ""]);
    mixpanel.track('Landing Page Open', {
      "screen": "index",
    });
    setTryFreeDownloadsPage(posthog.getFeatureFlag('try_free_downloads_page') === 'test');

    posthog.capture('Landing Page Open', {screen: 'index'})

    window.addEventListener('scroll', () => {
      handleScroll(setVisibleHeader, setPrevScrollPos);
    });
    return () => window.removeEventListener('scroll', () => {
      handleScroll(setVisibleHeader, setPrevScrollPos);
    })
  }, []);


  // Mamoto tracking:
  // push(["trackEvent", "contact", "click phone"]);


  const trackEmailSubscription = (email: string) => {
    // push(["trackEvent", "Landing Email Subscription", email]);
    mixpanel.track('Landing Email Subscription', {
      "email": email,
    })

    posthog.capture('Landing Email Subscription', {
      "email": email,
    })
  }


  const trackVideoClick = () => {
    // push(["trackEvent", "Landing Video Play Click", ""]);
    mixpanel.track('Landing Video Play Click', {})

    posthog.capture('Landing Video Play Click', {})
  }
  const trackVideoErrorClick = () => {
    // push(["trackEvent", "Landing Video Error Click", ""]);
    mixpanel.track('Landing Video Error Click', {})

    posthog.capture('Landing Video Error Click', {})
  }

  const onPlayStart = () => {
    if (player?.current != null) {
      player.current.play();
      trackVideoClick();
    } else {
      trackVideoErrorClick();
    }
    setShouldPlay(true);
  }

  const onPlayerReady = function () {
    setPlayerReady(true);
  }

  return (
    <main className={"main-page-bg"}>
      <AppHead/>

      <FloatingTryForFree visibleHeader={visibleHeader}/>

      <section className={"py-4 lg:py-8"}>
        <TopNavBar/>

        <motion.div
          key={"landing-block-top"}
          initial="initial"
          animate="animate"
          variants={{
            initial: {
              opacity: 0,
              y: 50
            },
            animate: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.5,
              }
            },
          }}
        >
          <div className={"flex flex-col md:flex-row mt-10 md:mt-20 w-100"}>
            <div className={"flex justify-center text-2xl w-full md:w-3/5 lg:w-1/2 md:pl-7 lg:pl-14 lg:pr-4"}>
              <div className={"mt-10 inline-block align-middle h-auto"}>
                <LandingBlockTop navigateToApp={navigateToApp}/>
              </div>
            </div>

            <div
              className={"md:bg-white md:bg-opacity-5 bg-gradient-1 overflow-clip w-full md:w-2/5 lg:w-1/2 mt-10 lg:mt-0 md:rounded-l-xl"}>
              <div className={"hidden lg:block image-feature-center-12-desktop-v2"}></div>
              <div className={"p-0"}>
                <div className={"block lg:hidden mt-5 image-feature-center-12-mob"}></div>
              </div>
            </div>
          </div>

        </motion.div>


        <motion.div
          key={"desktop-apps-block"}
          initial="initial"
          animate="animate"
          variants={{
            initial: {
              opacity: 0,
              y: 50
            },
            animate: {
              opacity: 1,
              y: 0,
              transition: {
                delay: 0.3,
                duration: 0.3,
              }
            },
          }}
        >
          <div className={"text-center mt-24"}>
            <h1 className={"text-xl"}>Download for your computer:</h1>
            <div className={"flex justify-center flex-col md:flex-row"}>
              <img
                onClick={() => navigateToApp(
                  downloadLinks.mac,
                  "macos-download", "macos-download")}
                className={"object-contain cursor-pointer"} style={{height: 68, marginTop: 6}}
                src={"/macos-dmg.png"} alt={"Download MacOS DMG"}/>
              <div className={"w-3"}></div>
              <img
                onClick={() => navigateToApp(
                  downloadLinks.win,
                  "windows-download", "windows-download")}
                className={"h-14 mt-3 object-contain cursor-pointer"}
                src={"/windows.svg"} alt={"Download Windows Installer"}/>
              <div className={"w-3"}></div>

              <div
                className={"bg-black border border-gray-400 rounded-lg cursor-pointer w-56 mx-auto md:mx-0"}
                onClick={() => navigateToApp(
                    tryFreeDownloadsPage ? "https://thebullish.trade/downloads" : "https://app.thebullish.trade",
                    tryFreeDownloadsPage ? "downloads" : "app", "landing-try-in-browser")}
                style={{height: 55, marginTop: 13, paddingTop: 3, paddingLeft: 7, paddingRight: 10}}>
                <div className={"flex"}>
                  <div className={"text-4xl"}
                       style={{paddingTop: 2, paddingLeft: 1, marginRight: 10}}>
                    🌎
                  </div>
                  <div className={"text-left mr-3"}>
                    <div className={"text-sm text-white"}>Try web app in your</div>
                    <div className={"font-bold text-lg text-white"}>Browser</div>
                  </div>
                </div>
              </div>
            </div>

            <div className={"mt-10"}>
              <p>
                We have apps for all the platforms. Yes, even Linux! 🐧
              </p>
              <p>
                See more in <a className={"border-b"} href={"/downloads"}>all downloads</a>
              </p>
            </div>
          </div>
        </motion.div>


        <motion.div
          key={"mobile-apps-block"}
          initial="initial"
          animate="animate"
          variants={{
            initial: {
              opacity: 0,
              y: 50
            },
            animate: {
              opacity: 1,
              y: 0,
              transition: {
                delay: 0.3,
                duration: 0.3,
              }
            },
          }}
        >
          <div className={"text-center mt-24"}>
            <h1 className={"text-xl"}>Prefer <span className={"font-bold"}>mobile apps?</span> We have them too!</h1>
            <div className={"flex justify-center flex-col md:flex-row"}>
              <img
                onClick={() => navigateToApp(
                  "https://apps.apple.com/us/app/the-bullish-trade/id6466982556",
                  "app-store", "ios-app")}
                className={"h-14 mt-3 object-contain"}
                src={"/appstore.svg"} alt={"Download on the App Store"}/>
              {/*<a*/}
              {/*    href='https://play.google.com/store/apps/details?id=trade.thebullish.app&pli=1&utm_source=the_bullish_landing&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>*/}
              <img
                onClick={() => navigateToApp(
                  "https://play.google.com/store/apps/details?id=trade.thebullish.app&pli=1&utm_source=the_bullish_landing&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",
                  "play-store", "android-app")}
                className={"h-20 cursor-pointer object-contain"}
                alt='Get it on Google Play'
                src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
              {/*</a>*/}

            </div>
          </div>
        </motion.div>


        <motion.div
          key={"landing-block-2"}
          initial="initial"
          animate="animate"
          whileInView="visible"
          viewport={{once: true}}
          variants={{
            initial: {
              opacity: 0,
              y: 50
            },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.5,
              }
            },
            hidden: {opacity: 0, scale: 0},
          }}
        >
          <div className={"flex flex-col lg:flex-row mt-36"}>
            <div
              className={"flex justify-center w-full lg:w-1/2 mt-5 flex-shrink lg:px-6"}>
              <div>
                <LandingBlockWhatIsBullishtrade/>
              </div>
            </div>

            <div className={"bg-gradient-1 bg-opacity-80 overflow-clip w-full lg:w-1/2 mt-10 lg:mt-0 lg:rounded-l-xl"}>
              <div className={"hidden md:block image-feature-center-6-desktop-v2"}></div>
              <div className={"p-0"}>
                <div className={"block md:hidden mt-5 image-feature-center-6-mob"}></div>
              </div>
            </div>
          </div>
        </motion.div>


        <motion.div
          key={"landing-block-3"}
          initial="initial"
          animate="animate"
          whileInView="visible"
          viewport={{once: true}}
          variants={{
            initial: {
              opacity: 0,
              y: 50
            },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.5,
              }
            },
            hidden: {opacity: 0, scale: 0},
          }}
        >
          <div className={"flex flex-col lg:flex-row mt-24"}>
            <div className={"hidden lg:block w-full lg:w-1/2"}>
              <div className={"bg-white bg-opacity-5 overflow-clip w-auto lg:rounded-r-xl"}>
                <div className={"hidden md:block lg:block image-feature-center-7-desktop-v2 "}></div>
                <div className={""}>
                  <div className={"block md:hidden lg:hidden image-feature-center-7-mob mt-5"}></div>
                </div>
              </div>
            </div>


            <div
              className={"flex justify-center w-full lg:w-1/2 mt-5 flex-shrink lg:px-6"}>
              <div>
                <LandingBlockFairPrice/>
              </div>
            </div>

            <div className={"w-full mt-10 lg:hidden"}>
              <div className={"bg-white bg-opacity-5 overflow-clip w-auto lg:rounded-r-xl"}>
                <div className={"hidden md:block lg:block image-feature-center-7-desktop-v2 "}></div>
                <div className={""}>
                  <div className={"block md:hidden lg:hidden image-feature-center-7-mob mt-5"}></div>
                </div>
              </div>
            </div>

          </div>
        </motion.div>


        <motion.div
          key={"landing-block-4"}
          initial="initial"
          animate="animate"
          whileInView="visible"
          viewport={{once: true}}
          variants={{
            initial: {
              opacity: 0,
              y: 50
            },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.5,
              }
            },
            hidden: {opacity: 0, scale: 0},
          }}
        >
          <div className={"flex flex-col lg:flex-row mt-36"}>
            <div
              className={"flex justify-center w-full lg:w-1/2 mt-5 flex-shrink lg:px-6"}>
              <div>
                <LandingBlockKeyMoves/>
              </div>
            </div>

            <div className={"md:bg-white md:bg-opacity-5 bg-gradient-1 overflow-clip w-full lg:w-1/2 mt-10 lg:mt-0 md:rounded-l-xl"}>
              <div className={"hidden lg:block image-feature-center-8-desktop-v2"}></div>
              <div className={"p-0"}>
                <div className={"block lg:hidden mt-5 image-feature-center-8-mob"}></div>
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          key={"landing-block-5"}
          initial="initial"
          animate="animate"
          whileInView="visible"
          viewport={{once: true}}
          variants={{
            initial: {
              opacity: 0,
              y: 50
            },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.5,
              }
            },
            hidden: {opacity: 0, scale: 0},
          }}
        >
          <div className={"flex flex-col lg:flex-row mt-36"}>
            <div className={"hidden lg:block w-full lg:w-1/2"}>
              <div className={"md:bg-white md:bg-opacity-5 bg-gradient-1 overflow-clip w-auto lg:rounded-r-xl"}>
                <div className={"hidden md:block lg:block image-feature-center-9-desktop-v2 "}></div>
                <div className={""}>
                  <div className={"block md:hidden lg:hidden image-feature-center-9-mob mt-5"}></div>
                </div>
              </div>
            </div>


            <div
              className={"flex justify-center w-full lg:w-1/2 mt-5 flex-shrink lg:px-6"}>
              <div>
                <LandingBlockFundamentals/>
              </div>
            </div>

            <div className={"w-full mt-10 lg:hidden"}>
              <div className={"md:bg-white md:bg-opacity-5 bg-gradient-1 overflow-clip w-auto lg:rounded-r-xl"}>
                <div className={"hidden md:block lg:block image-feature-center-9-desktop-v2 "}></div>
                <div className={""}>
                  <div className={"block md:hidden lg:hidden image-feature-center-9-mob mt-5"}></div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>


        <motion.div
          key={"landing-block-6"}
          initial="initial"
          animate="animate"
          whileInView="visible"
          viewport={{once: true}}
          variants={{
            initial: {
              opacity: 0,
              y: 50
            },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.5,
              }
            },
            hidden: {opacity: 0, scale: 0},
          }}
        >
          <div className={"flex flex-col lg:flex-row mt-36"}>
            <div
              className={"flex justify-center w-full lg:w-1/2 mt-5 flex-shrink lg:px-6"}>
              <div>
                <LandingBlockOptions/>
              </div>
            </div>

            <div className={"md:bg-white md:bg-opacity-5 bg-gradient-1 overflow-clip w-full lg:w-1/2 mt-10 lg:mt-0 md:rounded-l-xl"}>
              <div className={"hidden lg:block image-feature-center-10-desktop-v2"}></div>
              <div className={"p-0"}>
                <div className={"block lg:hidden image-feature-center-10-mob"}></div>
              </div>
            </div>
          </div>
        </motion.div>


        {/*<div className={"flex flex-col lg:flex-row mt-36"}>*/}

        {/*    <div className={"w-full lg:w-1/2"}>*/}
        {/*        <div className={"bg-slate-400 overflow-clip w-auto lg:rounded-r-xl"}>*/}
        {/*            <div className={"hidden md:block lg:block pt-10 "}>*/}
        {/*                <div className={" image-feature-center-4-desktop-v2"}></div>*/}
        {/*            </div>*/}
        {/*            <div className={""}>*/}
        {/*                <div className={"block md:hidden lg:hidden image-feature-center-2-mob mt-5"}></div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}

        {/*    <div className={"w-full lg:w-1/2 flex justify-center "}>*/}

        {/*        <div className={"mt-36 lg:mt-24 md:pl-5 lg:pl-8"}>*/}
        {/*            <LandingBlockIWantToTry/>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}

        <div
          className={"mt-20 lg:mt-32  text-center"}>
          <LandingBlockPricing/>
        </div>


        <div className={"mt-20 flex flex-row justify-center"}>
          <div>
                        {/*<span onClick={() => navigateToApp("https://app.thebullish.trade", "app", "mid-signup-free")}*/}
                        <span onClick={() => navigateToApp(tryFreeDownloadsPage ? "https://thebullish.trade/downloads" : "https://app.thebullish.trade",
                    tryFreeDownloadsPage ? "downloads" : "app", "mid-signup-free")}
                              className={"cursor-pointer btn-secondary-grad text-white px-8 py-4 text-xl font-bold rounded-xl"}>SIGNUP FOR
                            FREE</span>
            <div className={"mt-5 text-center"}>
              <span className={"text-xs text-gray-400"}>No credit card required. Instant signup.</span>
            </div>
          </div>
        </div>

        <div className={"mt-36 flex flex-col lg:flex-row"}>
          <div className={"flex justify-center text-2xl w-full lg:w-1/2 mt-7 lg:px-6"}>
            <div
              className={"mt-36 lg:mt-24"}>
              <LandingBlocksTldr/>
            </div>
          </div>


          <div className={"w-full lg:w-1/2"}>
            <div className={"hidden md:block lg:block mt-36 bg-white bg-opacity-5 overflow-clip lg:rounded-l-xl"}>
              <div className={"hidden md:block lg:block image-feature-center-11-desktop-v2 "}></div>
            </div>

            <div
              className={"block bg-gradient-1 md:hidden lg:hidden mt-36 overflow-clip lg:rounded-xl mx-auto"}
              style={{width: '100%', height: 720}}>
              <div className={"pt-5"}>
                <div className={"block md:hidden lg:hidden image-feature-center-11-mob mt-5"}></div>
              </div>
            </div>
          </div>
        </div>

        <div className={"mt-24 lg:mt-52 flex flex-row justify-center"}>
          <div>
                        {/*<span onClick={() => navigateToApp("https://app.thebullish.trade", "app", "cool-lets-trade")}*/}
                        <span onClick={() => navigateToApp(tryFreeDownloadsPage ? "https://thebullish.trade/downloads" : "https://app.thebullish.trade",
                    tryFreeDownloadsPage ? "downloads" : "app", "cool-lets-trade")}
                              className={"cursor-pointer btn-primary-grad text-white px-8 py-4 text-xl font-bold rounded-xl"}>{`Cool. Let's start!`}</span>
            <div className={"mt-5 text-center"}>
              <span className={"text-xs text-gray-400"}>No credit card required. Instant signup</span>
            </div>
          </div>
        </div>


        <div className={"mt-10 px-4"}>
          <EmailForm trackEmailSubscription={trackEmailSubscription}/>
        </div>


        <div className={"h-20"}>


        </div>
      </section>


      <Footer/>

      {/*<CrispChat/>*/}
    </main>

  )
}
